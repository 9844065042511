import { Double } from '@syncfusion/ej2-react-charts';
import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';
import { CareEsioEntity } from '../../../constants/CareESIO/CareEsioConstants';

export class IPayItemRow {
    UID?: string;
    Name?: string;
    Type?: string;
    ApplicableDay?: string;
    StartTime?: string;
    EndTime?: string;
    LoadingRate?: Double;
    PayLoadingId?: string;
    PayLoadingIdNumber?: number;
    IntegrationRef?: string;
    IntegrationRefUID?: string;

    PortalUID?: string;
    Portal?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;

    IsActive?: boolean;
}

export class PayItemRow extends IPayItemRow implements RAFEntityBase, ILookupRow {
    StartTimeDateValue?: Date;
    EndTimeDateValue?: Date;

    getClassName?() {
        return CareEsioEntity.CarePayItem.EntityName;
    }
    getIdField?(): string {
        return propertyOf<PayItemRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "PayItem/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "PayItem/Save";
    }
    getGroupByUrl?(): string {
        return "PayItem/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "PayItem/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
