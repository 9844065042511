import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
  useRef,
} from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RafClearDialogContent,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant } from "../../../constants/Common/Constants";
import RAFEdit from "../../Common/Update";
import MedicationTitleContent from "./MedicationTitleContent";

interface IProps {
  medicationRow: any;
  onSave: () => void;
  medicationEntity: EntityRow;
  uiMode?: "CardView" | "ListView";
  onSelect?: () => void;
  parent?: "CreateMedication" | "CareMedicationList";
  allowEdit?: boolean;
  cardClassName?: string;
  hideFooterContent?: boolean;
}

interface IState {
  showUpdateMedication: boolean;
}

function MedicationContent({
  medicationRow,
  uiMode = "CardView",
  parent = "CareMedicationList",
  allowEdit = true,
  hideFooterContent = false,
  ...props
}: PropsWithChildren<IProps>) {
  let deleteDialog: any;
  const medicationModule = CareEsioEntity.CareRecipientMedication.EntityName;
  let updateMedicationDialogRef = useRef<DialogComponent>(null);

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const hasPermissionToEdit = hasPermission(
    permissionValue,
    `${medicationModule}::Update`
  );

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showUpdateMedication: false,
    }
  );

  //UpdateMedicationRegiester start
  const showUpdateUpdateMedicationDialog = () => {
    if (hasPermissionToEdit && allowEdit) {
      setState({ showUpdateMedication: true });
    }
  };

  const updateUpdateMedicationContent = () => {
    if (state.showUpdateMedication) {
      return (
        <RAFEdit
          objectUID={medicationRow.UID}
          moduleName={medicationModule}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateMedicationRegiesterDialog.bind(this)}
          onDelete={onClickDeleteSection}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = async () => {
    await RafClearDialogContent(updateMedicationDialogRef);
    setState({ showUpdateMedication: false });
    if (props.onSave) {
      props.onSave();
    }
  };

  const closeUpdateMedicationRegiesterDialog = async () => {
    await RafClearDialogContent(updateMedicationDialogRef);
    setState({ showUpdateMedication: false });
  };
  //UpdateMedicationRegiester end

  //delete item start
  const onClickDeleteSection = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${CareEsioEntity.CareRecipientMedication.DisplayName}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let isDeleted = await DeleteRecord(
      medicationRow.UID,
      medicationModule,
      props.medicationEntity.UID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const onSelect = () => {
    if (isNotNullAndUndefined(props.onSelect)) {
      props.onSelect();
    }
  };

  const TimePeriod = (colClassName?) => {
    return (
      <RAFDetailFieldCustom
        value={
          isNotNullAndUndefined(medicationRow.TimePeriod)
            ? medicationRow.TimePeriod
            : "Not set"
        }
        title="Time Period"
        moduleName={medicationModule}
        isColorOption
        field="TimePeriod"
        mode="textOnly"
        labelClassName="text-transform-none details-label"
        {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
        rowClassName="gx-0 gy-1"
        showLabel={false}
      />
    );
  };

  const getCustomFooterContent = () => {
    if (hideFooterContent !== true) {
      return (
        <div>
          <div className="custom__card__separator custom__card__separator__light"></div>
          <div className="pe-3 py-2">
            <div className="row gx-2 align-items-center">
              {isNotNullAndUndefined(medicationRow.TimePeriod) && (
                <div className="col-auto">
                  {TimePeriod()}
                </div>
              )}
              <div className="col-auto">
                <RAFRecordInfo
                  createdBy={medicationRow.CreatedBy}
                  createdDate={medicationRow.CreatedDate}
                  modifiedDate={medicationRow.ModifiedDate}
                  modifiedBy={medicationRow.ModifiedBy}
                  lastActivityDate={medicationRow.LastActivityDate}
                  spanContent="Updated "
                  dropdownBtnClassName="custom-button-sm"
                  spanContentClassName="body_3_light medium"
                  hideClockIcon
                />
              </div>
              {(hasPermissionToEdit && allowEdit) && (
                <div className="col-auto ms-auto">
                  <RAFButtonComponent
                    action={RAFButtonConstant.Edit}
                    iconBtn
                    className="btn_state_warning white e-round"
                  ></RAFButtonComponent>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  if (isNotNullAndUndefined(medicationRow)) {
    return (
      <div className="col-12" key={medicationRow.UID}>
        {uiMode === "CardView" ? (
          <CustomCardWidget
            cardClassName={`${IsNotNullOrWhiteSpace(props.cardClassName) ? props.cardClassName : ''}${(allowEdit || parent === "CreateMedication") ? (IsNotNullOrWhiteSpace(props.cardClassName) ? " pointer " : 'pointer ') : ""}surface_neutral_base overflow-hidden`}
            // onClick={showUpdateUpdateMedicationDialog}
            onClick={() => {
              if (parent === "CreateMedication") {
                onSelect();
              } else {
                showUpdateUpdateMedicationDialog();
              }
            }}
            cardContentClassName="p-2 p-md-3"
            footerTemplate={getCustomFooterContent()}
          >
            <MedicationTitleContent medicationRow={medicationRow} />
          </CustomCardWidget>
        ) : (
          <div
            className={`border-bottom ${(hasPermissionToEdit && allowEdit) ? "pointer" : ""}`}
            onClick={() => showUpdateUpdateMedicationDialog()}
          >
            <div
              className="px-3 pt-2 pb-1"
              style={{
                margin: "2px 0",
                borderLeft: "3px solid rgb(187, 187, 187)",
              }}
            >
              <MedicationTitleContent medicationRow={medicationRow} />
            </div>
          </div>
        )}
        {state.showUpdateMedication && (
          <DialogComponent
            // header={
            //     "Update " +
            //     getDisplayNameByModuleName(medicationModule, props.medicationEntity.DisplayName)
            // }
            header={`Update ${CareEsioEntity.CareRecipientMedication.DisplayName}`}
            showCloseIcon
            visible={state.showUpdateMedication}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${medicationModule}`}
            content={updateUpdateMedicationContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateMedicationRegiesterDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={updateMedicationDialogRef}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(MedicationContent);
