import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { MomentFormats } from "../../../constants/Common/Constants";

const MedicationTitleContent = ({ medicationRow }) => {

    const medicationPurposeOrType = (colClassName?) => {
        return (
            <RAFDetailFieldCustom
                title="Medication Purpose or Type"
                value={medicationRow.MedicationPurposeOrType}
                labelDivClassName="col-auto"
                valueDivClassName="col-12"
                rowClassName="gx-0 gy-1"
                fieldFormat={{ type: RAFDataType.Dropdown }}
                moduleName={CareEsioEntity.CareRecipientMedication.EntityName}
                field="MedicationPurposeOrType"
                {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
                labelClassName="text-transform-none details-label"

            ></RAFDetailFieldCustom>
        );
    };

    const UnitOfMeasurment = (colClassName?) => {
        return (
            <RAFDetailFieldCustom
                title="Unit of Measurment"
                value={medicationRow.UnitOfMeasurment}
                labelDivClassName="col-auto"
                valueDivClassName="col-12"
                rowClassName="gx-0 gy-1"
                {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
                labelClassName="text-transform-none details-label"

            ></RAFDetailFieldCustom>
        );
    };

    const DosageCount = (colClassName?) => {
        return (
            <RAFDetailFieldCustom
                title="Dosage Count"
                value={medicationRow.DosageCount}
                labelDivClassName="col-auto"
                valueDivClassName="col-12"
                rowClassName="gx-0 gy-1"
                {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
                labelClassName="text-transform-none details-label"

            ></RAFDetailFieldCustom>
        );
    };

    const Assistance = (colClassName?) => {
        return (
            <RAFDetailFieldCustom
                title="Assistance"
                value={medicationRow.AssistanceType}
                labelDivClassName="col-auto"
                valueDivClassName="col-12"
                rowClassName="gx-0 gy-1"
                fieldFormat={{ type: RAFDataType.Dropdown }}
                moduleName={CareEsioEntity.CareRecipientMedication.EntityName}
                field="AssistanceType"
                {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
                labelClassName="text-transform-none details-label"
            ></RAFDetailFieldCustom>
        );
    };

    const getSubjectTemplate = () => {
        return (
            <div className="row g-0 gy-2 align-items-center justify-content-between">
                <div className="col-12">
                    <div className="row gx-2 align-items-center">
                        {/* {isNotNullAndUndefined(medicationRow.AdminMethods) &&
                            <div className="col-auto">
                                <div className="raf_badge raf_sm">
                                    <span>{medicationRow.AdminMethods}</span>
                                </div>
                            </div>
                        } */}
                        <div className="col">
                            <div className="subtitle_1 semi_bold">
                                {medicationRow.MedicationName ?? "not set"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">

                    <RAFDetailsValueWithSeparator
                        outerClassName="h-auto"
                        rowClassName="flex-nowrap g-2"
                    >
                        <RAFDetailFieldCustom
                            title="First Dose Date:"
                            value={medicationRow.FirstDoseDate}
                            labelDivClassName="col-auto"
                            valueDivClassName="col-auto"
                            rowClassName="gx-2"
                            fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
                        ></RAFDetailFieldCustom>
                        <RAFDetailFieldCustom
                            title="End Dose Date:"
                            value={medicationRow.EndDoseDate}
                            labelDivClassName="col-auto"
                            valueDivClassName="col-auto"
                            rowClassName="gx-2"
                            fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
                        ></RAFDetailFieldCustom>
                    </RAFDetailsValueWithSeparator>
                </div>
                {isNotNullAndUndefined(medicationRow.Dose) &&
                    <div className="col-12">
                        <CustomCardWidget cardContentClassName="p-2">
                            <span className="body_2_light">
                                {medicationRow.Dose}
                            </span>
                        </CustomCardWidget>
                    </div>
                }
                <div className="col-12 mt-3 d-none d-xl-block">
                        <RAFDetailsValueWithSeparator
                            outerClassName="line-seprator h-auto"
                            rowClassName="flex-nowrap gx-3"

                        >
                            {medicationPurposeOrType()}
                            {Assistance()}
                        </RAFDetailsValueWithSeparator>
                </div>
                <div className="col-12 d-xl-none">
                    <div className="row g-2 g-md-3">
                        {medicationPurposeOrType('col-12 col-md-6')}
                        {Assistance('col-12 col-md-6')}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            {getSubjectTemplate()}
        </div>
    );
};

export default MedicationTitleContent;