import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import {
  RafClearDialogContent,
  getAllAttributes,
  getFields,
  getRelatedRecords,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  ConvertSystemName,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareMedicationPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant } from "../../../constants/Common/Constants";
import RAFCreate from "../../Common/Create";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import MedicationContent from "./MedicationContent";

interface IProps {
  careRecipientUID: string;
  uiStyle?: "CardStyle" | "DetailsStyle" | "SummaryStyle" | "SummaryDetails";
  showAddBtn?: boolean;
  paddingClass?: string;
  allowEdit?: boolean;
  isCollapsed?: boolean;
  cardClassName?: string;
}

interface IState {
  isLoading: boolean;
  medicationItems: any;
  medicationEntity: EntityRow;
  showCreateMedicationContent: boolean;
  showUpdateMedicationContent: boolean;
  medicationRow: any;
  medicationDivKey: number;
}

function MedicationList({
  showAddBtn = true,
  uiStyle = "CardStyle",
  paddingClass = "p-2-5 p-md-3",
  isCollapsed = false,
  ...props
}: PropsWithChildren<IProps>) {
  const medicationModule = CareEsioEntity.CareRecipientMedication.EntityName;
  let addMedicationDialogRef = useRef<DialogComponent>(null);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      medicationItems: null,
      medicationEntity: null,
      showCreateMedicationContent: false,
      showUpdateMedicationContent: false,
      medicationRow: null,
      medicationDivKey: Math.random(),
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID]);

  const refresh = async () => {
    if (isNotNullAndUndefined(props.careRecipientUID)) {
      setState({
        isLoading: true,
        medicationEntity: null,
      });

      const [medicationEntity, attributeRelatedList, queryAttributes] = await Promise.all([
        getEntityByObjectName({
          ObjectName: ConvertSystemName(medicationModule),
        }),
        getAllAttributes(ConvertSystemName(medicationModule)),
        getFields(ConvertSystemName(medicationModule)),
      ]);//dont remove this line careInterventionSupportEntity is used in loop below this line add to avoid multiple calls

      const medicationItems = await getRelatedRecords(
        medicationModule,
        null,
        "CareRecipientUID",
        props.careRecipientUID,
        medicationEntity.UID
      );

      setState({ isLoading: false, medicationItems, medicationEntity });
      // setState({ isLoading: false, medicationEntity });
    }
  };

  const createMedicationDlgDiv = () => {
    return (
      <div>
        {state.showCreateMedicationContent && (
          <DialogComponent
            header={`Add ${CareEsioEntity.CareRecipientMedication.DisplayName}`}
            showCloseIcon
            visible={state.showCreateMedicationContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_create_${medicationModule}`}
            content={createMedicationContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeCreateMedicationDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={addMedicationDialogRef}
          />
        )}
      </div>
    );
  };

  const medicationHeaderContent = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="header_5">
          {CareEsioEntity.CareRecipientMedication.CollectionName}
        </div>
        <div>
          {state.isLoading === false && showAddBtn === true && (
            <div className="col-12 d-flex justify-content-center">
              <RAFPermissionRender
                permissionName={
                  CareMedicationPermissionConstants.CareMedicationAdd
                }
              >
                <RAFButtonComponent
                  action={RAFButtonConstant.Add}
                  onClick={() => createMedicationDialogOpen()}
                  className="btn_brand_primary semi_dark"
                />
              </RAFPermissionRender>
              {createMedicationDlgDiv()}
            </div>
          )}
        </div>
      </div>
    );
  };

  const medicationListItemSummary = () => {
    if (isNotEmptyArray(state.medicationItems)) {
      return (
        <div className="row gy-2 gy-md-3 g-0">
          {state.medicationItems.map((item) => {
            return (
              <div className={"col-auto"} key={item.UID}>
                <div className="raf_badge surface_neutral_base">
                  <span className="body_2_dark ecllipseSecondLine">
                    {item.MedicationName}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  let medicationCount = isNotEmptyArray(state.medicationItems)
    ? state.medicationItems.length
    : 0;
  const getMedicationListContent = () => {
    if (uiStyle === "DetailsStyle") {
      return (
        <div className="row g-3">
          <div className="col-12">{medicationHeaderContent()}</div>
          {medicationListItem()}
          {/* {state.isLoading === false && showAddBtn === true && (
            <div className="col-12">
              <RAFPermissionRender
                permissionName={
                  CareMedicationPermissionConstants.CareMedicationAdd
                }
              >
                <RAFButtonComponent
                  className="btn-dashed btn-roundedCorner w-100 e-outline"
                  isPrimary
                  // className="custom-button-md"
                  action={RAFButtonConstant.Add}
                  btnContent={`Add ${CareEsioEntity.CareRecipientMedication.DisplayName}`}
                  onClick={() => createMedicationDialogOpen()}
                />
              </RAFPermissionRender>
              {createMedicationDlgDiv()}
            </div>
          )} */}
        </div>
      );
    } else if (uiStyle === "SummaryStyle") {
      return (
        <CustomCardWidget cardContentClassName="p-0" key={medicationCount}>
          <RAFCollapseWithPlusIcon
            toggleArrowIcon="Arrow"
            title="Medications"
            badge={{
              count: medicationCount,
              show: true,
              className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
            }}
            isCollapsed={
              isCollapsed === true ? true : medicationCount > 0 ? false : true
            }
            collapsible={medicationCount > 0 ? true : false}
            collapsePanelHeaderClass={"collapsePanel__header__fixedHeight__lg"}
            collapsePanelContentClassname={paddingClass}
          >
            {medicationListItemSummary()}
          </RAFCollapseWithPlusIcon>
        </CustomCardWidget>
      );
    } else if (uiStyle === "SummaryDetails") {
      return (
        <CustomCardWidget cardContentClassName="p-0">
          <RAFCollapseWithPlusIcon
            toggleArrowIcon="Arrow"
            title="Medications"
            collapsePanelHeaderClass={"collapsePanel__header__fixedHeight__lg"}
            // collapsePanelHeaderClass={"px-0"}
            allowFullRowClick
            // collapsePanelContentClassname="p-0"
            badge={{
              count: medicationCount,
              show: true,
              className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
            }}
            isCollapsed={isCollapsed}
            collapsible={true}
            // showSeparator={false}
            customButton={
              <div>
                {showAddBtn !== false && (
                  <div className="d-flex align-items-baseline">
                    <div className="col-auto">
                      <RAFPermissionRender
                        permissionName={
                          CareMedicationPermissionConstants.CareMedicationAdd
                        }
                      >
                        <RAFButtonComponent
                          className={`custom-button-sm primary-custom-button`}
                          // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                          // className="custom-button-md"
                          action={RAFButtonConstant.Add}
                          // {...!BrowserIsDevice ?
                          //   { btnContent: `Add ${CareEsioEntity.CareRecipientMedication.DisplayName}` }
                          //   :
                          //   {}
                          // }
                          onClick={() => createMedicationDialogOpen()}
                        />
                      </RAFPermissionRender>
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <div>
              {createMedicationDlgDiv()}
              {medicationListItem()}
            </div>
          </RAFCollapseWithPlusIcon>
        </CustomCardWidget>
      );
    } else {
      return (
        <div className="col-12">
          <div className="custom__card overflow-hidden bg-light-blue-3">
            <div className="custom__card__content p-0">
              <RAFCollapse
                title={CareEsioEntity.CareRecipientMedication.CollectionName}
                headerRowClassName="with-height"
                // headerRowClassName="with-height bg-card-header-8"
                contentCardClassName="relatedListOuterDiv"
                headerBadge={
                  isNotEmptyArray(state.medicationItems) && (
                    <div className="secondary-text">
                      <span className="e-badge e-badge-grey-border e-badge-circle e-badge-md e-badge-notification ms-2 position-static">
                        {state.medicationItems.length}
                      </span>
                    </div>
                  )
                }
                removeContentCardPadding
                IsCollapsed={true}
                customButton={
                  <div>
                    {showAddBtn !== false && (
                      <div className="d-flex align-items-baseline">
                        <div className="col-auto">
                          <RAFPermissionRender
                            permissionName={
                              CareMedicationPermissionConstants.CareMedicationAdd
                            }
                          >
                            <RAFButtonComponent
                              className={`custom-button-sm primary-custom-button`}
                              // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                              // className="custom-button-md"
                              action={RAFButtonConstant.Add}
                              // {...!BrowserIsDevice ?
                              //   { btnContent: `Add ${CareEsioEntity.CareRecipientMedication.DisplayName}` }
                              //   :
                              //   {}
                              // }
                              onClick={() => createMedicationDialogOpen()}
                            />
                          </RAFPermissionRender>
                        </div>
                      </div>
                    )}
                  </div>
                }
              // Collapsible={showExpandBtn}
              >
                <div>
                  {createMedicationDlgDiv()}
                  {medicationListItem()}
                </div>
              </RAFCollapse>
            </div>
          </div>
        </div>
      );
    }
  };

  const medicationListItem = () => {
    if (state.isLoading === false) {
      if (isNotEmptyArray(state.medicationItems)) {
        return (
          <div className="col-12">
            <div className={`custom__card__content ${paddingClass}`}>
              <div className="row g-0 gy-2 gy-md-3">
                {state.medicationItems.map((item) => {
                  return (
                    <MedicationContent
                      key={item.UID}
                      medicationRow={item}
                      medicationEntity={state.medicationEntity}
                      onSave={refresh}
                      allowEdit={uiStyle === "SummaryStyle" ? false : true}
                      cardClassName={props.cardClassName}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <>
            <div className="col-12">
              <RAFEmptyState
                title={`No ${CareEsioEntity.CareRecipientMedication.DisplayName} recorded.`}
                {...(showAddBtn === true && {
                  body: `To get started, please add a new ${CareEsioEntity.CareRecipientMedication.DisplayName} by clicking on the "Add" button.`,
                })}
              />
            </div>
          </>
        );
      }
    } else {
      return (
        <div className="col-12">
          <div className="custom__card__content p-0">
            <div className="row g-3">
              <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Preparing Data..." />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  //addMedication start
  const createMedicationDialogOpen = () => {
    setState({ showCreateMedicationContent: true });
  };

  const createMedicationContent = () => {
    if (state.showCreateMedicationContent) {
      let initialValues = {};

      const objectContext = React.useContext(RAFObjectContext);
      const rafObject = isNotNullAndUndefined(objectContext)
        ? objectContext.rafObject
        : null;
      if (isNotNullAndUndefined(rafObject)) {
        initialValues["CareRecipientUID"] = rafObject.UID;
        initialValues["CareRecipient"] = rafObject.first_name;
      }

      return (
        <div className="showInputInCard h-100">
          <RAFCreate
            initialValues={initialValues}
            onSave={refreshOnCreate.bind(this)}
            onClose={closeCreateMedicationDialog.bind(this)}
            moduleName={medicationModule}
            isActive
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnCreate = async () => {
    await RafClearDialogContent(addMedicationDialogRef);
    refresh();
    setState({
      showCreateMedicationContent: false,
      medicationDivKey: Math.random(),
    });
  };

  const closeCreateMedicationDialog = async () => {
    await RafClearDialogContent(addMedicationDialogRef);
    setState({ showCreateMedicationContent: false });
  };

  //addMedication end

  if (isNotNullAndUndefined(props.careRecipientUID)) {
    return (
      <RAFPermissionRender
        permissionName={CareMedicationPermissionConstants.CareMedicationRead}
      >
        {getMedicationListContent()}
      </RAFPermissionRender>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(MedicationList);
