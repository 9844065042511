import { DialogUtility } from "@syncfusion/ej2-popups";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";

import moment from "moment";
import RAFDateTimePicker from "../../../RAFComponents/Inputs/RAFDateTimePicker";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RAFActionMessage,
  RetrieveRecord,
  hideProgress,
  showProgress
} from "../../../RAFComponents/helpers/AppHelper";
import { getDisplayNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  isNotNullAndUndefined,
  propertyOf
} from "../../../RAFComponents/helpers/utils";
import { RAFUIType } from "../../../RAFComponents/models/Common/RAFDataType";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACNumber from "../../../components/shared/ACFormFields/ACNumber";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import { PayItemRow } from "./PayItemRow";

export const savePayItemAPI = (
  payItemRow?: PayItemRow,
  moduleName?: string
) => {
  return new Promise<{ entityId: string; objectName: string; }>(
    async (resolve) => {
      let saveRequestData = getSaveRequest(payItemRow, payItemRow.UID);
      if (isNotNullAndUndefined(moduleName)) {
        saveRequestData.EntityName = moduleName;
      }
      if (isNotNullAndUndefined(payItemRow)) {
        repositoryActions
          .postDataAndGetResponse(
            "PayItem/Save",
            saveRequestData,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId)
            ) {
              resolve({
                entityId: response.data.EntityId,
                objectName: response.data.ObjectName,
              });
            } else {
              resolve({ entityId: null, objectName: null });
            }
          })
          .catch((error) => resolve({ entityId: null, objectName: null }));
      } else {
        resolve({ entityId: null, objectName: null });
      }
    }
  );
};

interface IProps {
  initialValues?: PayItemRow;
  onDelete?: () => void;
  objectUID?: string;
}


interface IState {
  isLoading: boolean;
  noContent: boolean;
  payItemRow: PayItemRow;
}

function ManagePayItem({ ...props }: PropsWithChildren<IProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;

  const moduleName = CareEsioEntity.CarePayItem.EntityName;
  const outerDivId = `manageupdate_pay_item_dialog`;

  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      payItemRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });
      const [payItemRow] = await Promise.all([getIntitailPayItemRow()]);

      if (isNotNullAndUndefined(payItemRow)) {
        setState({ isLoading: false, noContent: false, payItemRow });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const getIntitailPayItemRow = () => {
    return new Promise<PayItemRow>(async (resolve) => {
      if (isNotNullAndUndefined(props.objectUID)) {
        const payItemRow: PayItemRow = await RetrieveRecord(props.objectUID, moduleName);
        if (
          isNotNullAndUndefined(payItemRow) &&
          isNotNullAndUndefined(payItemRow.UID)
        ) {
          if (isNotNullAndUndefined(payItemRow.StartTime)) {
            const startTime = moment()
              .startOf("day")
              .add(moment.duration(payItemRow.StartTime));
            payItemRow.StartTimeDateValue = startTime.toDate();
          }
          if (isNotNullAndUndefined(payItemRow.EndTime)) {
            const endTime = moment()
              .startOf("day")
              .add(moment.duration(payItemRow.EndTime));
            payItemRow.EndTimeDateValue = endTime.toDate();
          }
          resolve(payItemRow);
        } else {
          resolve(null);
        }
      } else {
        let initialObject = isNotNullAndUndefined(props.initialValues)
          ? props.initialValues
          : new PayItemRow();

        resolve(initialObject);
      }
    });
  };

  const onSubmitObject = async (
    values,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    const formValue = { ...values } as PayItemRow;

    const startTimeDateValue = formValue.StartTimeDateValue;
    const endTimeDateValue = formValue.EndTimeDateValue;

    delete formValue.StartTimeDateValue;
    delete formValue.EndTimeDateValue;

    formValue.StartTime = moment(startTimeDateValue).format("HH:mm");
    formValue.EndTime = moment(endTimeDateValue).format("HH:mm");

    let progressDiv = showProgress(`#${outerDivId}`);
    const response = await savePayItemAPI(formValue, moduleName);
    hideProgress(progressDiv);

    if (
      isNotNullAndUndefined(response) &&
      isNotNullAndUndefined(response.entityId)
    ) {
      showSuccessToast("Saved successfully");
      if (props.onSave)
        props.onSave(response.entityId, response.objectName);
    } else {
      showWarningToast(
        `Apologies, we're unable to save the ${getDisplayNameByModuleName(
          moduleName
        )} at the moment. Please try again later.`
      );
    }
  };

  const onSubmitObject1 = (
    values,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    //let progressDiv = showProgress("#update-page-outerDiv");
    //const timeFormat = /^(0?[1-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const timeFormat = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    const formValue = { ...values } as PayItemRow;

    const startTime = formValue.StartTime;
    const endTime = formValue.EndTime;

    if (!startTime.match(timeFormat) || !endTime.match(timeFormat)) {
      showWarningToast("Please enter valid time format.");
      return;
    } else {
      const startDate = new Date(`1970-01-01T${startTime}:00`);
      const endDate = new Date(`1970-01-01T${endTime}:00`);

      if (endDate <= startDate) {
        showWarningToast("End time cannot be less than start time.");
        return;
      }
    }

    saveObject(values)
      .then((response) => {
        //hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");
          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast(
            `Apologies, we're unable to save the ${getDisplayNameByModuleName(
              moduleName
            )} at the moment. Please try again later.`
          );
        }
      })
      .catch((error) => error);
  };

  //delete start
  function DeleteClicked(entity: EntityRow) {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this, entity) },
      title: `Delete ${getDisplayNameByModuleName(
        moduleName,
        entity.DisplayName
      )}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  }

  async function deleteRecord(entity: EntityRow) {
    let progressDiv = showProgress(".deleteDialog.e-dialog");

    const isDeleted = await DeleteRecord(
      props.objectUID,
      moduleName,
      entity.UID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (props.onDelete) {
        props.onDelete();
      } else if (props.onSave) {
        props.onSave();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast(RAFActionMessage.RecordNotDeleted);
    }
  }
  //delete end

  const getStartEndTimeContent = () => {
    return (
      <>
        <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<PayItemRow>("StartTimeDateValue")}
            label="Start Time"
            uiType={RAFUIType.TimeOnly}
            interval={1}
            roundOff
            required
            formGroupClassName="mb-0"
            timePickerampm={false}
          />
        </div>
        <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<PayItemRow>("EndTimeDateValue")}
            label="End Time"
            uiType={RAFUIType.TimeOnly}
            interval={1}
            roundOff
            required
            formGroupClassName="mb-0"
            timePickerampm={false}
          />
        </div>
      </>
    );
  };


  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        return (
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFAttributesContext.Consumer>
                {({ queryAttributes }) => {
                  return (
                    <RAFEntityContext.Consumer>
                      {({ entity }) => {
                        return (
                          <RAFForm
                            initialValues={state.payItemRow}
                            formRef={(g) => {
                              return (rafForm = g);
                            }}
                            layout={RAFLayout.TwoColumnLayout}
                            onSubmit={(value) =>
                              onSubmitObject(value)
                            }
                            primaryKey={props.objectUID}
                            className="h-100"
                          >
                            <div className="e-dlg-content-outer">
                              <div className="e-dlg-body-content">
                                <div className="row gx-2 gy-4">
                                  <ACTextBox
                                    field={propertyOf<PayItemRow>("Name")}
                                    label="Name"
                                    required
                                  />
                                  <ACDropdown
                                    field={propertyOf<PayItemRow>("Type")}
                                    label="Type"
                                    moduleName={moduleName}
                                    allowAdd={false}
                                    required
                                  />
                                  <ACDropdown
                                    field={propertyOf<PayItemRow>(
                                      "ApplicableDay"
                                    )}
                                    label="Applicable Day"
                                    moduleName={moduleName}
                                    allowAdd={false}
                                    required
                                  />
                                  {getStartEndTimeContent()}
                                  {/* <ACTextBox
                                          field={propertyOf<PayItemRow>(
                                            "StartTime"
                                          )}
                                          label="Start Time"
                                          required
                                        />
                                        <ACTextBox
                                          field={propertyOf<PayItemRow>(
                                            "EndTime"
                                          )}
                                          label="End Time"
                                          required
                                        /> */}
                                  <ACNumber
                                    field={propertyOf<PayItemRow>(
                                      "LoadingRate"
                                    )}
                                    label="Loading Rate"
                                    decimalsPoints={2}
                                    required
                                  />
                                  {isNotNullAndUndefined(
                                    props.objectUID
                                  ) && (
                                      <RAFLookUpMUI
                                        field={propertyOf<PayItemRow>(
                                          "IntegrationRef"
                                        )}
                                        url={
                                          Constants.baseRAFXeroUrl +
                                          "api/" +
                                          "PayItem/XeroLookup"
                                        }
                                        label="XERO Pay Item"
                                      />
                                    )}
                                </div>
                              </div>
                              <div className="e-dlg-footerContent">
                                <div className="w-100">
                                  <div className="row gx-2">
                                    {isNotNullAndUndefined(
                                      props.objectUID
                                    ) && (
                                        <div className="col-auto">
                                          <RAFButtonComponent
                                            action={
                                              RAFButtonConstant.Delete
                                            }
                                            onClick={() =>
                                              DeleteClicked(entity)
                                            }
                                            idString="DeleteContent"
                                            className="e-danger e-outline"
                                          />
                                        </div>
                                      )}
                                    <div className="col-auto ms-auto">
                                      <RAFButtonComponent
                                        isPrimary
                                        action={RAFButtonConstant.Save}
                                        onClick={() =>
                                          rafForm && rafForm.form.submit()
                                        }
                                        idString="EditContent"
                                        disabled={
                                          rafForm && rafForm.submitting
                                        }
                                      />
                                    </div>
                                    <div className="col-auto">
                                      <RAFButtonComponent
                                        action={RAFButtonConstant.Cancel}
                                        onClick={props.onClose}
                                        idString="EditContent"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </RAFForm>
                        );
                      }}
                    </RAFEntityContext.Consumer>
                  );
                }}
              </RAFAttributesContext.Consumer>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManagePayItem);
